.calendarClassName {
  font-family: 'Gilroy';
  display: flex;
  border: 0;
  box-shadow: 0px 8px 8px -4px #10182808;
  box-shadow: 0px 20px 24px -4px #10182814;
  border-radius: 8px;
  margin-top: 4px;
}

.calendarClassName .react-datepicker__month {
  display: flex;
  flex-direction: column;
}

.calendarClassName .react-datepicker__month-container {
  display: flex;
  flex-direction: column;
  padding: 20px 20px;
}

.calendarClassName .react-datepicker__day {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
  font-size: 14px !important;
  font-weight: 400 !important;
  line-height: 20px !important;
  color: #333d55;
  border-radius: 0;
}

.calendarClassName .react-datepicker__day--today {
  border-radius: 50%;
  background: #f1f4fb !important;
}

.calendarClassName .react-datepicker__day:hover {
  background-color: #f8fafe;
  border-radius: 50%;
  font-weight: 500;
}

.calendarClassName .react-datepicker__day--in-range:hover {
  border-radius: 0;
}

.calendarClassName .react-datepicker__day--in-selecting-range {
  background: #f8fafe !important;
  font-weight: 500 !important;
  color: #333d55 !important;
}

.calendarClassName .react-datepicker__day--in-range {
  font-weight: 500 !important;
  background: #f8fafe !important;
  color: #333d55 !important;
}

.calendarClassName .react-datepicker__day--selected {
  background-color: transparent;
}

.calendarClassName .react-datepicker__day--selected .datePickerDayWrapper,
.calendarClassName .react-datepicker__day--range-end .datePickerDayWrapper {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7b69be !important;
  border-radius: 50% !important;
  font-weight: 500 !important;
  color: #fff !important;
}

.calendarClassName .react-datepicker__week .react-datepicker__day--in-range:first-child,
.calendarClassName .react-datepicker__week .react-datepicker__day--in-selecting-range:first-child,
.calendarClassName .react-datepicker__day--range-start,
.calendarClassName .react-datepicker__day--selecting-range-start {
  border-top-left-radius: 50% !important;
  border-bottom-left-radius: 50% !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.calendarClassName .react-datepicker__week .react-datepicker__day--in-range:last-child,
.calendarClassName .react-datepicker__week .react-datepicker__day--in-selecting-range:last-child,
.calendarClassName .react-datepicker__day--range-end,
.calendarClassName .react-datepicker__day--selecting-range-end {
  border-top-right-radius: 50% !important;
  border-bottom-right-radius: 50% !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.calendarClassName
  .react-datepicker__week
  .react-datepicker__day--selecting-range-end.react-datepicker__day--in-selecting-range:first-child,
.calendarClassName
  .react-datepicker__week
  .react-datepicker__day--selecting-range-end.react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range,
.calendarClassName
  .react-datepicker__week
  .react-datepicker__day--selecting-range-start.react-datepicker__day--in-selecting-range:last-child,
.calendarClassName
  .react-datepicker__week
  .react-datepicker__day--range-start.react-datepicker__day--in-range:last-child {
  border-radius: 50% !important;
}

.calendarClassName .react-datepicker__week {
  margin-bottom: 4px;
  height: 40px;
  display: flex;
}

.calendarClassName .react-datepicker__week:last-child {
  margin-bottom: 0;
}

.calendarClassName .react-datepicker__day-name {
  width: 40px !important;
  height: 40px !important;
  margin: 0 !important;
  font-family: Gilroy;
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  letter-spacing: 0em;
  text-align: center;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}

/* Header */

.calendarClassName .react-datepicker__header {
  padding: 0 !important;
  border-bottom: none;
  background-color: #ffffff;
}

.calendarClassName .react-datepicker__current-month {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 34px;
  font-family: Gilroy;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
}

.calendarClassName .react-datepicker__day--disabled {
  color: #667085;
}

.calendarClassName .react-datepicker__day--disabled.react-datepicker__day:hover {
  background-color: transparent;
}

@media (max-width: 550px) {
  .calendarClassName {
    flex-direction: column;
  }
}
